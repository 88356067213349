<template>
  <div>
    <div class="lineHeader">
      <div class="input-group" id="searchbar">
          <span class="input-group-text">活動類別</span>
          <select
            v-model="selected"
            @change="ontype()"
            class="form-select"
            aria-label="First name"
          >
          <option value="all" >全部</option>
          <option v-for="list in myJson" :value="list.value" :key="list.value">{{list.title}}</option>
          </select> 
          <a-button type="primary"  @click="openArie()"  :size="'large'">
            <b-icon icon="geo-alt-fill"></b-icon>
          </a-button>
          <a-button type="danger"  @click="openContent()" shape="round" icon="heart" :size="'large'" />   

      </div>
      
    </div>
      <b-modal ref="my-lines" size="sm" centered @hide="onHide" id="LineLg" title="Line 登入" hide-header hide-footer>
        <div class="text-center">
        <Linelogin />
        </div>
      </b-modal>  
      
      <a-modal
        :title= "'我的最愛❤'"
        :visible="visible"
        :closable=true   
        :maskClosable=false
        :okText="'刪除'"
        :okType="'danger'"
        :cancelText= "'取消'"
        :bodyStyle="{ padding:'10px'}"
        @cancel="handelCancel"
      >
      <div class="text-center overflow-auto">
         <div style="padding:0rem;max-height: 550px;" v-if="user!==null" class="text-left">
          <b-list-group>
            <b-list-group-item button v-for="data in user.data.user.acts_loves" @click="query(data)" :key="data.ids">
              <b-avatar variant="primary" :src="data.pri_url" :size="36" class="align-baseline"></b-avatar>
              <span v-text="data.name" style="padding-left: 0.5rem;font-size: medium;" />
            </b-list-group-item>
          </b-list-group>
         </div>
      </div>
      <template slot="footer">
          <div class="text-center">
          </div>          
      </template>
      </a-modal>

      <a-modal
        :title= "'我的區域'"
        :visible="visible2"
        :closable=true   
        :maskClosable=false
        :okText="'刪除'"
        :okType="'danger'"
        :cancelText= "'取消'"
        :bodyStyle="{ padding:'10px'}"
        @cancel="handelCancel2"
      >
      <div class="text-center overflow-auto">
         <div style="padding:0rem;max-height: 550px;" v-if="user!==null" class="text-center">
          <a-row type="flex" justify="center" :align="'middle'" >
              <a-col :span="10" v-for="data in groupedData" :key="data.village">                
                <a-button shape="round"  @click="toArea(data.village)" style="margin:0.4rem;" :size="'large'" >
                  <b-icon icon="geo-alt-fill"  aria-hidden="true"></b-icon>
                  <span v-text="data.village" style="padding-left: 0.5rem;font-size: medium;" />
                </a-button>
              </a-col>                  
          </a-row>  
         </div>
      </div>
      <template slot="footer">
          <div class="text-center">
          </div>          
      </template>
      </a-modal>

  </div>
</template>

<script>
import Linelogin from "../../auth/Linelogin.vue";
import MY_JSON from '../../assets/json/optiondata.json'
import TW_JSON from '../../assets/json/twdata.json'
import { mapState, mapActions } from "vuex";
export default {
  name: "Linemenubar",
  components: {
    Linelogin,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      myJson: MY_JSON, 
      twJson: TW_JSON,
      visible:false, 
      visible2:false,       
      groupedData:[],
      show: null,
      selected:null,
    };
  },
  methods: {
    ...mapActions("account", ["checktoken", "logout"]),

    showline() {
      this.$refs["my-lines"].show();      
    },

    closeline() {
      this.$refs["my-lines"].hide();      
    },    
 
    onHide(evt) {
          if(evt.trigger === "backdrop"){
              evt.preventDefault();
          }
      },
    ontype(){
      this.$emit("ontype",this.selected)
    },
    openContent(){
      this.visible=true;
    },
    handelCancel(){
      this.visible=false
    },
    openArie(){
      this.visible2=true;
    },    
    handelCancel2(){
      this.visible2=false
    },    
    query(item){
      this.$emit("onByOrg",item)
      this.handelCancel()
    },
    toArea(val){
      this.$emit("getArea",process.env.VUE_APP_AREA+val)
      this.handelCancel2()
    }
   
  },
  async created() {
    const filteredData = this.twJson.filter(item => item.city === process.env.VUE_APP_AREA);
    const uniqueVillages = [...new Set(filteredData.map(item => item.village))];
    this.groupedData = uniqueVillages.map(village => ({ village }));
  },

  computed: {
    ...mapState("account", ["status", "user"]),
  },
};
</script>
<style scoped>
.lineHeader {
   position: fixed;
   left: 0;
   top: 0;
   z-index: 7;
   opacity:1;
   width: 100%;
   height: 3.3rem;
   padding: 0.5rem;
   background-color: #3b1b75;
   color: #f0f8f8;
   text-align: center;
   
}
#searchbar{
  width:80%;
  margin: 0 auto;
}
</style>
<style>
#LineLg > .modal-dialog > .modal-content > .modal-body {
  border-color: transparent;
  background-color: transparent !important;
  color: white;
} 
#LineLg > .modal-dialog > .modal-content {
  border-color: transparent;  
  background-color: transparent !important;
} 
</style>