<template>
  <div class="overflow-hidden">
    <a-spin :spinning="spinnings" tip="資料載中...">
      <div class="d-flex flex-column min-vh-100">
        <a-drawer
          title="活動訊息"
          placement="right"
          :closable="false"
          :visible="display"
          :after-visible-change="afterVisibleChange"
          @close="onClose"
        >
          <a-card :title="cardtitle" :bordered="false">
            <div v-html="cardcontent"></div>
          </a-card>
        </a-drawer>
        <Linemenubar @ontype="ontype" @onByOrg="onByOrg" @getArea="getArea" msg="test" ref="con1" />
        <ul>
          <li>
            <div class="mt-3">
              <div style="padding:1.7rem 0 0 0;" />
              <Viewper :item="list" @loads="loads" id="style-3" ref="con2" />
              <div v-if="list!==undefined" style="margin:2rem;"> 
                <a-spin v-if="list.length==0" tip="無活動資料, 請確認...">
                </a-spin>          
              </div>              
            </div>
          </li>
        </ul>
        <div class="applyfooter">
          <Mybottommenu @savelove="savelove" ref="con3" />
        </div>
      </div>

      <a-modal
          title="帳號邦定"
          :visible="showModal"
          @cancel="closeModal"
          :confirm-loading="false"
          :centered="false"  
          :mask-closable="false"
          >
          <a-form>
            <a-form-item :label="''">
                <a-input 
                    v-model="myAccount" 
                    placeholder="請輸入帳號"
                ></a-input>
                <a-input 
                    type="password"
                    v-model="myPassword" 
                    placeholder="請輸入密碼"
                ></a-input>
                
                <a-row :gutter="16">
                  <a-col :span="14">
                      <a-input 
                          v-model="verify" 
                          placeholder="請輸入驗證碼"
                      ></a-input>                                                
                  </a-col>
                  <a-col :span="10">
                      <div @click="refreshCode" style="cursor: pointer;">
                          <Identify :identifycode="identifyCode" ></Identify>
                      </div>                
                  </a-col>
                </a-row>                                
            </a-form-item>
          </a-form> 
          <template slot="footer">
              <a-button @click="closeModal">取消</a-button>
              <a-button @click="bindBtn()" :disabled="myAccount=='' || myPassword=='' || myCheck || identifyCode==''" type="primary">登入</a-button>          
          </template>
      </a-modal>

    </a-spin>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import router from "../router";
import { myLineService } from "../_services";
import { userService } from "../_services/";
import * as moment from "moment/moment";
import Linemenubar from "@/myline/panel/Linemenubar.vue";
import { mixinWebsocket } from "../wsocket/wsobj.js";
import Mybottommenu from "../myline/panel/Mybottommenu.vue";
import Identify from "../auth/Identify.vue";
import Viewper from "./panel/Viewper.vue";
import store from "../store/index";

const queryData = params => {
  return myLineService.finddata(
    params.page,
    params.size,
    params.id,
    params.mods
  );
};

const postData = (params) => {
  return userService.myLogin(params);
};

export default {
  name: "Myline",
  mixins: [mixinWebsocket],
  components: {
    Linemenubar,
    Viewper,
    Mybottommenu,
    Identify
  },
  props: {
    msg: String
  },
  data() {
    return {
      spinnings: false,
      showModal: false,
      myAccount:"",
      myPassword:"",           
      page: 1,
      iconLoading: false,
      list: [],
      uId: "",
      visible: false,
      answer: "",
      display: false,
      cardtitle: "",
      cardcontent: "",
      verify:'',
      identifyCode: '',
      identifyCodes: '123456789abcdefghijklmnopqstuwxyzABCDEFGHGKMNPQRSTUVWXYZ',        
    };
  },
  computed: {
    ...mapState("account", ["status", "user", "linecheck"]),
    ...mapState("ws", ["wsNotify", "wsState", "socket"]),
      myCheck(){
        return this.verify.toLowerCase()!=this.identifyCode.toLowerCase()?true:false
      }    
  },
  async mounted() {
    let obj = { id: "Myline" };
    await this.setorgpage(obj)
      .then(val => {
        return val;
      })
      .catch(val => {
        return val;
      })
      .finally(() => {
        console.log("set page done");
      });
    let names = store.state.account.targetpage;
    if (names == "Myline") {
      this.orgint();
    }
    console.log("User ID : ",this.user.data.user.id);
  },

  watch: {
    wsNotify(val) {
      if (val) {
        let { data } = val;
        if (data !== null) {
          if (data.type === "order") {
            this.cardtitle = data.title;
            this.cardcontent = data.rmg;
            this.display = true;
          }
        }
      }
    },

    socket(val) {
      if (val !== null) {
        if (val.url == undefined) {
          this.initWebsocket();
        }
      }
    }
  },
  methods: {
    ...mapActions("account", ["logout", "checktoken","binLogin"]),
    ...mapMutations("account", ["setpage", "getLinecheck", "logout"]),
    query(rs) {
      this.classtheme = rs;
    },

    closeModal() {
      this.spinnings = false;
      this.showModal = false;
    },
    
    refreshCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },

    makeCode(o, l) {
        for (let i = 0; i<l; i++) {
            this.identifyCode += this.identifyCodes[
                Math.floor(Math.random() * (this.identifyCodes.length - 0) + 0)
                ]
        }  
     },     
    
    async bindBtn() {
      if(this.myAccount!=='' && this.myPassword!=='' ){
          let item = {
          id:this.user.data.user.id,
          username: this.myAccount,
          password: this.myPassword, 
          utoken:this.user.data.user.utoken,
          pri_url:this.user.data.user.pri_url       
        };
        this.spinnings = true;
        let rs = await this.myLogin(item)
        if (rs.code== 200){
          this.cardtitle = "Line帳號綁定";
          this.cardcontent = "綁定成功";
          this.display = true;
          let targetPages = store.state.account.targetpage;
          setTimeout(() => {
            this.spinnings = false;
            this.display = false;
            this.closeModal();
            this.binLogin({username: this.myAccount,password: this.myPassword,targetPage:targetPages})
          }, 1000);
        }else if(rs.code== 404){
          this.cardtitle = "Line帳號綁定";
          this.cardcontent = "請確認密碼, 或是帳不是否存在, 或已被綁定";
          this.display = true;
          this.spinnings = false;
          setTimeout(() => {
            this.display = false;
          }, 1000);                    
        }
      }
    },
    
    myLogin(item) {
      return new Promise((resolve) => {         
          postData({
            ...item,
          }).then(({ data }) => {
              resolve(data);            
          });
        });
      },     

    setorgpage(obj) {
      return new Promise(resolve => {
        resolve(this.setpage(obj));
      });
    },

    async orgint() {
      //let obj= {id:this.$route.name}
      //await this.setorgpage(obj).then((val)=>{return val}).catch((val)=>{return val}).finally(() => {console.log('set page done')})
      this.getLinecheck();
      if (this.linecheck == false) {
        this.logout();     
        this.$refs["con1"].showline();
		setTimeout(() => {
          this.$refs["con1"].handelCancel2();
         }, 600); 
		
                   
      } else {
        this.uId = this.user.data.user.id;
        this.fetch();
        this.initWebsocket();
        if(this.user.data.use){
          router.replace('/setting').catch(err=>{err})
        }
      }
    },

    getmore() {
      this.fetch();
    },

    loads(va1){
      this.spinnings=va1
    },

    ontype(val) {
      this.$refs["con2"].ontype(val);
    },

    onByOrg(val) {
      this.$refs["con2"].onByOrg(val);
    },
    getArea(val){
      this.$refs["con2"].getArea(val);
    },

    fetch(
      params = {
        page: this.page,
        size: 3,
        id: this.uId,
        mods: "N"
      }
    ) {
      queryData({
        ...params
      }).then(({ data }) => {
        let obj = data.data;
        if (obj != null) {
          this.page += 1;
          setTimeout(() => {
            //this.list.push(...obj);
            let myobj = this.myPush(obj);   
            this.list.push(...myobj);              
            //for (var prop in obj) {
            //   if(this.list.find(rs=>rs.id==obj[prop].id)==undefined){
            //    this.list.push(obj[prop]);
            //   } 
            //} 
            this.iconLoading = false;                                
          }, 100); 
        } else {
          this.iconLoading = false;
        }
      });
    },

    myPush(obj) {
        // 使用 Fisher-Yates 洗牌算法來隨機排列陣列
        for (let i = obj.length - 1; i > 0; i--) {
            //const j = Math.floor(Math.random() * (i + 1));
            const j = Math.floor(Math.random() * obj.length); // 範圍擴大到整個陣列
            [obj[i], obj[j]] = [obj[j], obj[i]]; // 交換元素位置
        }
        return obj;
    },    

    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD HH:mm");
    },

    savelove(val) {
      if(val=='bind'){
        this.cardtitle = "";
        this.cardcontent = "";        
        this.myAccount=""
        this.myPassword="" 
        this.spinnings = false;
        this.verify="" 
        this.refreshCode()
        this.showModal = true;
      }else{
        this.$refs["con2"].postdata(true, val);
      }
    },

    scanCode(val) {
      this.answer = val;
      this.visible = true;
      setTimeout(() => {
         this.showTop = false;
         }, 4000);      
    },

    afterVisibleChange(val) {
      console.log("visible", val);
    },
    onClose() {
      this.display = false;
    }
  },
  async created() {
    if (this.linecheck !== false && this.linecheck !==undefined){
      setTimeout(() => {
          const myOpenState = JSON.parse(sessionStorage.getItem('openMyArie'));
          if (!myOpenState) {
            this.$refs["con1"].openArie()
          }else{
            sessionStorage.setItem('openMyArie', 'false');
          }
         }, 300);	
    }
  },
  
  beforeRouteLeave(to, from, next) {
    if(to.name=='Myapply'){      
      this.$refs["con2"].saveRoute();
    }
    sessionStorage.setItem('openMyArie', 'true'); 
    next();
  },    
};
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.sidenav a:hover {
  color: #064579;
}
.applyfooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  color: #f0f8f8;
  text-align: center;
  z-index: 7;
}
.contantdiv {
  margin: 5px;
  overflow-x: hidden;
  overflow-y: hidden;
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(152, 167, 189, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
}

#style-3 {
  text-align: left;
}

.spin-content {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  padding: 30px;
}
</style>

