<template>
  <div>
    <div  style="width:100%;max-height:60px;" >      
        <div class="modbtn" @click="toGo()" > <b-icon icon="clock-history" aria-hidden="true"></b-icon> 活動紀錄</div>
        <div class="mcodbtn" @click="toGoScore()" > <b-icon icon="list-ul" aria-hidden="true"></b-icon> 健康學分</div>             
        <div class="rightbtn" @click="toGoact()" ><b-icon icon="map" aria-hidden="true"></b-icon> 報到點名</div>
        <div class="rightSide" @click="toBind()" ><b-icon icon="person-badge" aria-hidden="true"></b-icon> 綁定帳號</div>         
    </div> 
  </div>
</template>

<script>
//import router from '../../router'
import { mapState } from "vuex";
//import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'


export default {
  name: "Mybottommenu",  
  props: {
    obj: Object, 
  },
  data() {
    return {     
      xurl: "",
      imgs: {
        width: 0,
        height: 0
      },
    };
  },
  computed: {
    ...mapState("account", ["user"]),
  },  
  methods: {

    catImg(imgs) {
      return require(`../../assets/${imgs}`);
    },

    covert(type,val){
      if(type=='theme'){        
        let rs= this.mytheme.filter((el)=> { return el.value === val});
        return rs[0].title;
      }
    },

    toGo(){
      this.$emit("savelove","love")
      //this.user.data.user.id
    },  
    
    toGoScore(){
      this.$emit("savelove","score")
    },
    
    toGoact(){
    this.$emit("savelove","act")
    }, 
    
    toBind(){
      if(this.user.data.user.utoken!==''){
        this.$emit("savelove","bind")
      }
    }, 
    
    onHide(evt) {
          if(evt.trigger === "backdrop"){
              evt.preventDefault();
          }
      },     

  },

};
</script>
<style scoped>
.leftbtn {
    background-color: #aedbe3;
    color:#4b5152;
    width:17.5%; 
    height:60px; 
    float:left; 
    display:inline;
    text-align:center;
    line-height:60px;
}
.leftbtn:hover {
    background-color: #b6c8cc;
    color:#ffffff;
    cursor: pointer;
}
.modbtn{
    background-color: #30c272;
    color:#ffffff;
    width:25%; 
    height:60px; 
    float:left; 
    display:inline;
    text-align:center;
    line-height:60px;
}
.modbtn:hover {
    background-color: #63a380;
    color:#ffffff;
    cursor: pointer;
}

.mcodbtn{
    background-color: #1bc2a6;
    color:#ffffff;
    width:25%; 
    height:60px; 
    float:left; 
    display:inline;
    text-align:center;
    line-height:60px;
}
.mcodbtn:hover {
    background-color: #128a76;
    color:#ffffff;
    cursor: pointer;
}

.rightbtn {
    background: linear-gradient(90deg, #65b5f7 0%, #4995d3 35%, #4184d0 100%);
    width:25%; 
    height:60px; 
    float:left; 
    display:inline;
    border-width:1px;
    border-color: #65b5f7;
    border-left-style:dotted;
    text-align:center; 
    line-height:60px;
}

.rightbtn:hover {
    background: linear-gradient(90deg, #7dc3fd 0%, #62b5f8 35%, #4aa0e7 100%);
    cursor: pointer;
}

.rightSide {
    background: linear-gradient(90deg, #7d94fd 0%, #626cf8 35%, #614ae7 100%);
    width:25%; 
    height:60px; 
    float:left; 
    display:inline;
    border-width:1px;
    border-color: #65b5f7;
    border-left-style:dotted;
    text-align:center; 
    line-height:60px;
}

.rightSide:hover {
    background: linear-gradient(90deg, #a37dfd 0%, #8a62f8 35%, #bb4ae7 100%) ;
    cursor: pointer;
}
</style>


