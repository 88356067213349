<template>
  <div id="itemlist" class="overflow-auto" style="height: 800px;">
    <div style="height: 960px;">
      <div style="padding:2.2rem 0 0 0;" />
      <div v-if="aclist.length==0" style="margin-top:2rem;">            
          <div style="padding:2rem;">
            <span style="background-color: white;color: black;font-size: large;font-weight: bold;" v-text="'無相關活動資料, 請確認謝謝。。。'"></span>
          </div>                  
      </div>      
      <div
        v-for="(item, $index) in aclist"
        :key="$index"  
        style="margin:5px 5px 15px 5px;border-top-right-radius:25px; 
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;"      
      >
        <b-card
          :img-src="item.url!==''? item.url:mainImg('event_1080x540.png')"
          img-alt="Image"
          img-top
          class="mb-2"
        >
          <b-card-text>
            <div v-if="item.theme==selected || selected=='all' || orgid!=='N' || selected!=='N' || area!=='N'" style="cursor:pointer;" @click="toConten(item.fid)" >
                    <div class="fontstyle">
                      <div v-text="rename(item.actitle)"  />
                      <div v-text="'活動類別 : '+ getinfo(item.theme)"  />
                      <div v-text="'活動日期 :'"  />
                      <div v-html="fdateformat(item)"  />
                      <div v-text="'(詳細內容...)'" style="font-weight:bold;" />
                    </div>
                  </div> 
          </b-card-text>
        </b-card>
      </div>    
      <infinite-loading @infinite="infiniteHandler" spinner="waveDots" :distance="5">
        <span slot="no-more" v-if="info!==null">
          <div v-if="info!==null && aclist.length>0">
            <a-button v-if="info.totail>(info.page * info.results)"  style="margin: 20px;"  @click="infiniteHandler()" type="primary" shape="round" icon="download" size="large" >取得更多資料</a-button>
          </div>
          <div style="padding:2rem;">
          <span v-if="info.totail<=(info.page * info.results)" style="background-color: white;color: black;font-size: large;font-weight: bold;" v-text="'無相關活動資料, 謝謝。。。'"></span>
          </div>
        </span>
      </infinite-loading>
      <div style="padding:3.5rem 0 0 0;" />
    </div>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import { actService } from "../../_services";
import MY_JSON from "../../assets/json/optiondata.json";
import { mapState, mapMutations } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import router from "../../router";
import store from "../../store/index";

const queryACata = obj => {
  //return actService.getactlistall(obj);
  return actService.getItemAct(obj)
};

export default {
  name: "Viewper",
  components: {
    InfiniteLoading
  },
  props: {
    msg: String,
    item: Array,
    winsize: Object
  },
  data() {
    return {
      myJson: MY_JSON,
      page: 1,
      orgid:'N',
      area:'N',
      checkgo: false,
      aclist: [],
      aclove: [],
      info:null,
      objs: {
        uid: "",
        fidlist: []
      },
      selected: "all",
      action: null,
      items: "",
      queryMyLineState:{},
    };
  },
  async mounted() {
    if (this.user !== null) {
      let obj = { id: "Myline" };
      await this.setorgpage(obj)
        .then(val => {
          return val;
        })
        .catch(val => {
          return val;
        })
      let names = store.state.account.targetpage;
      if (names == "Myline") {
        this.getLinecheck();
        if (this.linecheck == true) {
          const savedMyLineQueryState = JSON.parse(sessionStorage.getItem('queryMyState'));
          if (savedMyLineQueryState) {
            this.queryMyLineState = savedMyLineQueryState;
            this.page=this.queryMyLineState.page,
            this.selected=this.queryMyLineState.selected,
            this.orgid=this.queryMyLineState.orgid,
            this.area=this.queryMyLineState.area,
            this.aclist=this.queryMyLineState.aclist
            sessionStorage.removeItem('queryMyState');
          }else{            
            this.getfetch();
          } 
        }
      }
      window.addEventListener("beforeunload", e => this.beforeunloadHandler(e));
    }
  },
  computed: {
    ...mapState("account", ["user", "linecheck"])
  },

  methods: {
    ...mapMutations("account", ["setpage", "getLinecheck"]),
    fdateformat(val) {
      return (
        moment(val.sdate).format("YYYY-MM-DD HH:mm") +
        "~" +
        moment(val.edate).format("MM-DD HH:mm")
      );
    },
    catImg(imgs) {
      return require(`../../assets/img/${imgs}`);
    },
    mainImg(imgs) {
      return require(`../../assets/${imgs}`);
    },     
    getinfo(theme) {
      let url = this.myJson.find(rs => rs.value == theme).title;
      return url;
    },

    rename(value){
      return value.length >= 25 ? value.substring(0,17)+'...':value
    },

    setorgpage(obj) {
      return new Promise(resolve => {
        resolve(this.setpage(obj));
      });
    },

    getfetch() {
        this.infiniteHandler();
    },

    getxt(val) {
      return val.substring(1, 32);
    },

    ontype(val) {
      this.orgid = 'N';
      this.area = 'N';
      this.aclist=[]
      this.selected = val;
      this.page=1
      this.infiniteHandler();
    },

    onByOrg(val){
      this.orgid = val.ids;
      this.area = 'N';
      this.aclist=[]
      this.selected = 'N';
      this.page=1
      this.infiniteHandler();
    },

    getArea(val){
      this.area = val;
      this.orgid =  'N';
      this.aclist=[]
      this.selected = 'N';
      this.page=1
      this.infiniteHandler();
    },

    toConten(id) {
      this.postdata(false, "");
      router.push({ name: "Myapply", params: { id } });
    },

	async infiniteHandler($state) {
      this.iconLoading = true;
      this.$emit('loads',true);
      var my_date = new Date();
      var first_date = new Date(my_date.getFullYear(), my_date.getMonth()-12, 1);

      let params = {
        page: this.page,
        size: 4,
        type: this.selected,
        date: moment(first_date).format("YYYY-MM-DD HH:mm"),
        states:'Y',
        orgid:this.orgid,
        area:this.area
      }
      let rs=await this.getRuner(params)
      if(rs){
        $state.loaded();
      }else{
        $state.complete();
      }
      if($state.loaded()==undefined){
        this.$emit('loads',false)
      }      
    }, 
    
    getRuner(params) {
      return new Promise((resolve) => {
      this.page++;
      queryACata({
        ...params
      }).then(({ data }) => {
        let obj = {
          hits: data.results
        };
        if (obj.hits == null) {
          obj.hits = [];
        }
        this.$emit('loads',false)
        if (obj.hits.length) {
          this.info = data.info
          let myData = obj.hits
          let myobj = this.myPush(myData);   
          this.aclist.push(...myobj);  
          //for (var prop in myData) {
          //    if(this.aclist.find(rs=>rs.id==myData[prop].id)==undefined){
          //     this.aclist.push(myData[prop]);
          //    } 
          //} 
          //this.aclist.push(...obj.hits);
          resolve(true);
        } else {
          resolve(false);
        }
      });
      });
    }, 
    
    myPush(obj) {
        // 使用 Fisher-Yates 洗牌算法來隨機排列陣列
        for (let i = obj.length - 1; i > 0; i--) {
            //const j = Math.floor(Math.random() * (i + 1));
            const j = Math.floor(Math.random() * obj.length); // 範圍擴大到整個陣列
            [obj[i], obj[j]] = [obj[j], obj[i]]; // 交換元素位置
        }
        return obj;
    },     

    postdata(val, item) {
      this.checkgo = val;
      this.items = item;
      this.togo();
    },
    togo() {
      if (this.checkgo) {
        if (this.items == "love") {
          router.replace({ name: "Mylove" });
        } else if(this.items == "score"){
          router.replace({ name: "MyScoreData" });
        } else {
          router.replace({ name: "Mydoing" });
        }
      }
      this.checkgo = false;
    },

    beforeunloadHandler(e) {
      console.log(e);
      this.postdata(false, "");
    },

    saveRoute(){
        this.queryMyLineState={
          page: this.page,
          selected: this.selected,
          orgid:this.orgid,
          area:this.area,
          aclist:this.aclist
      }
      sessionStorage.setItem('queryMyState', JSON.stringify(this.queryMyLineState));
    }
  },
  created() {},
  destroyed() {
    window.removeEventListener("beforeunload", e =>
      this.beforeunloadHandler(e)
    );
  },

    
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#itemlist {
  margin: 0px auto;
  padding: 0px;
  max-width: 1024px;
}

img {
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
  display: block;
  clip-path: polygon(-4px -4px, 100.4% -3px, 99.2% 102.57%, -0.8% 102.97%);
}

.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  width: min(98%, 500px);
  margin: 0 auto;
  box-shadow: 0 20px 34px hsla(20deg, 1%, 52%, 0.29);
  overflow: hidden;
}

.cards-details {
  position: relative;
  padding: 0 0.05rem 0 0.5rem;
}

.fontstyle {

  font-size: 0.93rem;
  font-weight:bold;
  color: #2c055e;
  padding: 0.4rem;
  background:linear-gradient(45deg,#7fdac1 0%,#5decde 60%,#ddf0e6 60%,#93cea2 100%);
  opacity:0.85;

}

.spin-content {
  padding: 60px;
  box-shadow: 0 20px 34px hsla(20deg, 1%, 52%, 0.29);
}

.card-body{
  padding:0px;
}

.card-img-top{
  border-top-right-radius:25px;
}
.card{
  border-top-right-radius:25px;
}
</style>





